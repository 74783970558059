import React, {useEffect} from 'react';
import RFZOPatientsTable from "./RFZOPatientsTable/RFZOPatientsTable";
import {useDispatch, useSelector} from "react-redux";
import {fetchRFZOUpcomingUsers} from "../../../store/rfzoTherapy-actions";
import {RootState} from "../../../store";
import Button from '../../UI/Buttons/Button/Button';

type Props = {
    dateFrom: string,
    dateTo: string
}

const RFZOPatients = ({dateFrom, dateTo}:Props) => {

    const dispatch = useDispatch();

    const isAdmin = useSelector((state:RootState) => state.auth.isAdmin);
    const marketplaceInfo = useSelector((state: RootState) => state.auth.marketplaceInfo);
    const rfzoUpcomingUsers = useSelector((state: RootState) => state.rfzoTherapy.rfzoUpcomingUsers);

    useEffect(() => {
        if (isAdmin || localStorage.getItem("roles")?.includes('ADMIN')) {
            dispatch<any>(fetchRFZOUpcomingUsers(dateFrom, dateTo));
        } else {
            dispatch<any>(fetchRFZOUpcomingUsers(dateFrom, dateTo, marketplaceInfo.branchMarketplaceId && marketplaceInfo.branchMarketplaceId, marketplaceInfo.marketplaceId));
        }
    }, [])

    return (
        <div>
            {rfzoUpcomingUsers.length > 0 ?
                <>  
                    <Button buttonType="blue">Dodaj podsetnik za novog pacijenta</Button>
                    <div>Lista korisnika kojima RFZO terapija prispeva od izabranog datuma.</div>
                    <RFZOPatientsTable usersData={rfzoUpcomingUsers}/>
                </>
                :
                <div>Nema rezultata za traženi datum.</div>
            }
        </div>
    );
};

export default RFZOPatients;