import React, { useEffect, useState } from "react";
import styles from "./PharmaciesImagesModal.module.scss";
import SimpleModal from "../../UI/Modals/SimpleModal/SimpleModal";
import Button from "../../UI/Buttons/Button/Button";
import ImageUploading from "react-images-uploading";
import uploaderImage from "../../../assets/icons/uploader.svg";
import trashIcon from "../../../assets/icons/trash.svg";
import editIcon from "../../../assets/icons/edit.svg";

type Props = {
  currentImages: any[];
  submitImageChange: Function;
};

function PharmaciesImagesModal({ currentImages, submitImageChange }: Props) {
  const [show, setShow] = useState(false);

  const [imgList, setImgList] = useState([]);
  const [initImageList, setInitImageList] = useState(currentImages);

  useEffect(() => {
    setInitImageList(currentImages);
  }, [currentImages]);

  return (
    <>
      <Button className={styles.editButton} onClick={() => setShow(true)} buttonType="white">
        <img className={styles.Icons} src={editIcon} />
      </Button>
      {show && (
        <SimpleModal backdropClickHandler={() => setShow(false)} show={show} className={styles.Modal}>
          <ImageUploaderWrap
            initImageList={initImageList}
            setInitImageList={setInitImageList}
            imageList={imgList}
            setImageList={setImgList}
            label="Slike"
            maxNumber={10}
          />
          <Button
            onClick={() => {
              submitImageChange(imgList, initImageList);
              setShow(false);
              setImgList([])
            }}
            buttonType="blue">
            Sacuvaj
          </Button>
          <Button onClick={() => setShow(false)} buttonType="white">
            Odustani
          </Button>
        </SimpleModal>
      )}
    </>
  );
}

export default PharmaciesImagesModal;

const ImageUploaderWrap = ({ initImageList, setInitImageList, imageList, setImageList, maxNumber = 1 }: any) => {
  const onChange = (value: any) => {
    setImageList(value);
  };

  const onInitImageRemove = (value: any) => {
    const filter = initImageList.filter((i: any) => i != value);
    setInitImageList(filter);
  };

  return (
    <ImageUploading multiple value={imageList} onChange={onChange} maxNumber={maxNumber}>
      {({ imageList, onImageUpload, isDragging, dragProps, onImageRemove }) => (
        <div className={styles.uploadWrapper}>
          <div
            className={styles.imageUploadField}
            style={isDragging ? { color: "red" } : undefined}
            onClick={onImageUpload}
            {...dragProps}>
            <img height={64} width={64} src={uploaderImage} />
            <div>Dodajte fotografiju ili je prevucite u polje.</div>
          </div>
          {imageList &&
            imageList?.map((image, index) => (
              <div className={styles.uploadedImage}>
                <img height="100%" style={{ aspectRatio: 1 }} src={image.dataURL} alt="" width="100%" />
                <div style={{ display: "flex", justifyContent: "space-between" }} className="image-item__btn-wrapper">
                  <Button className={styles.changeImageButton} buttonType="white" onClick={() => onImageRemove(index)}>
                    <img className={styles.Icons} src={trashIcon} />
                  </Button>
                </div>
              </div>
            ))}
          {Array.isArray(initImageList) &&
            initImageList?.map((image: any, index: number) => (
              <div key={index} className={styles.uploadedImage}>
                <img
                  height="100%"
                  style={{ aspectRatio: 1 }}
                  src={process.env.REACT_APP_CDN_PATH + "marketplace_images/" + image}
                  alt=""
                  width="100%"
                />
                <div style={{ display: "flex", justifyContent: "space-between" }} className="image-item__btn-wrapper">
                  <Button
                    className={styles.changeImageButton}
                    buttonType="white"
                    onClick={() => onInitImageRemove(image)}>
                    <img className={styles.Icons} src={trashIcon} />
                  </Button>
                </div>
              </div>
            ))}
        </div>
      )}
    </ImageUploading>
  );
};
