import React, { useEffect, useState } from "react";
import styles from "./PharmacyDetails.module.scss";
import Button from "../../UI/Buttons/Button/Button";
import TextField from "../../TextField/TextField";
import { baseUrl } from "../../../axios/axios";
import axios from "../../../axios/axios";
import NotificationMessage from "../../UI/NotificationMessage/NotificationMessage";

const StoreInfo = ({ marketplaceInfoDetailed, marketplaceWorkingHours }: any) => {
  const [marketplaceInfo, setMarketplaceInfo] = useState<any>();

  useEffect(() => {
    setMarketplaceInfo(marketplaceInfoDetailed);
  }, [marketplaceInfoDetailed]);

  const onDetailsChange = (field: any, value: any, isNested: boolean = false) => {
    if (isNested) {
      setMarketplaceInfo((prev: any) => {
        let branchMarketplaceResponse = prev.branchMarketplaceResponse;
        branchMarketplaceResponse.name = value;
        return { ...prev, branchMarketplaceResponse: branchMarketplaceResponse };
      });
    } else {
      setMarketplaceInfo((prev: any) => ({ ...prev, [field]: value }));
    }
  };

  const saveMarketplaceChanges = () => {
    updateMarketPlaceDetails(marketplaceInfo).then(() => successfulNotification());
  };

  const [notificationMessageVisible, setNotificationMessageVisible] = useState(false);

  const successfulNotification = () => {
    setNotificationMessageVisible(true);
    setTimeout(() => {
      setNotificationMessageVisible(false);
    }, 5000);
  };

  if (!marketplaceInfo) return null;

  return (
    <div className={styles.pharmacyDetailsWrap}>
      {notificationMessageVisible && <NotificationMessage>Uspesno izmenjeni podaci</NotificationMessage>}
      <div className={styles.header}>Profil apoteke</div>
      <Row>
        <TextField
          label="Naziv*"
          placeHolder=""
          value={marketplaceInfo?.name || ""}
          onChange={(val: any) => onDetailsChange("name", val)}
        />
        <TextField
          label="Naziv ustanove*"
          placeHolder=""
          value={marketplaceInfo?.branchMarketplaceResponse?.name || ""}
          onChange={(val: any) => onDetailsChange("", val, true)}
        />
      </Row>
      <Row>
        <TextField
          label="Broj telefona*"
          placeHolder="*011/123456"
          value={marketplaceInfo?.phoneNumber || ""}
          onChange={(val: any) => onDetailsChange("phoneNumber", val)}
        />
        <TextField
          label="Email*"
          placeHolder=""
          value={marketplaceInfo?.email || ""}
          onChange={(val: any) => onDetailsChange("email", val)}
        />
      </Row>
      <TextField
        label="Website"
        placeHolder="www.example.com"
        value={marketplaceInfo?.website || ""}
        onChange={(val: any) => onDetailsChange("website", val)}
      />
      <div>
        <TextField
          label="Adresa prodajnog mesta*"
          placeHolder=""
          value={marketplaceInfo?.address || ""}
          onChange={(val: any) => onDetailsChange("address", val)}
        />
      </div>

      <Row>
        <TextField
          label="Mesto*"
          placeHolder=""
          value={marketplaceInfo?.location || ""}
          onChange={(val: any) => onDetailsChange("location", val)}
        />
        <TextField
          label="Postanski broj*"
          placeHolder=""
          value={marketplaceInfo?.postalCode || ""}
          onChange={(val: any) => onDetailsChange("postalCode", val)}
        />
      </Row>
      <Row>
        <TextField
          label="Latitude*"
          placeHolder=""
          value={marketplaceInfo?.latitude || ""}
          onChange={(val: any) => onDetailsChange("latitude", val)}
        />
        <TextField
          label="Longitude*"
          placeHolder=""
          value={marketplaceInfo?.longitude || ""}
          onChange={(val: any) => onDetailsChange("longitude", val)}
        />
      </Row>
      <TextField
        label="Više o apoteci"
        placeHolder="kratak opis..."
        value={marketplaceInfo?.description || ""}
        onChange={(val: any) => onDetailsChange("description", val)}
      />
      <Button
        onClick={() => {
          saveMarketplaceChanges();
        }}
        buttonType="blue">
        Sacuvaj izmene
      </Button>

      <WorkingHours marketplaceWorkingHours={marketplaceWorkingHours} />
    </div>
  );
};

export default StoreInfo;

const WorkingHours = ({ marketplaceWorkingHours }: any) => {
  const [workingHours, setWorkingHours] = useState({});
  const [notificationMessageVisible, setNotificationMessageVisible] = useState(false);

  const successfulNotification = () => {
    setNotificationMessageVisible(true);
    setTimeout(() => {
      setNotificationMessageVisible(false);
    }, 5000);
  };

  const branchMarketplaceId = localStorage.getItem("branchMarketplaceId");
  const marketplaceId = localStorage.getItem("marketplaceId");

  useEffect(() => {
    let workingHoursDraft = {};
    if (marketplaceWorkingHours) {
      for (const [day, time] of Object.entries(marketplaceWorkingHours)) {
        if (time === "Ne radi") {
          // @ts-ignore
          workingHoursDraft[day] = { start: "00:00", end: "00:00" };
        } else {
          // @ts-ignore
          const [start, end] = time.split(" do ");
          // @ts-ignore
          workingHoursDraft[day] = { start, end };
        }
      }
      setWorkingHours(workingHoursDraft);
    }
  }, [marketplaceWorkingHours]);

  const onWorkingHoursChange = (val: any, day: any, period: any) => {
    console.log(workingHours);
    setWorkingHours((prev) => {
      return {
        ...prev,
        [day]: {
          // @ts-ignore
          ...prev[day],
          [period]: val,
        },
      };
    });
  };

  return (
    <>
      {notificationMessageVisible && <NotificationMessage>Uspesno izmenjeni podaci</NotificationMessage>}
      <div className={styles.subHeader}>Radno vreme (ponedeljak-nedelja)</div>
      <div className={styles.description}>Radna vremena uneti u obliku: 00:00</div>
      {Object.keys(DAYS).map((day) => (
        <div className={styles.singleDay}>
          {/* @ts-ignore */}
          <div className={styles.dayLabel}>{DAYS[day].label}</div>
          <Row>
            {/* @ts-ignore */}
            <TextField
              wrapClass={styles.singleDayTextField}
              label=""
              placeHolder=""
              // @ts-ignore
              value={workingHours[day]?.start || ""}
              onChange={(val: any) => {
                onWorkingHoursChange(val, day, "start");
              }}
            />
            <span className={styles.timeSeparator}>do</span>
            {/* @ts-ignore */}
            <TextField
              wrapClass={styles.singleDayTextField}
              label=""
              placeHolder=""
              // @ts-ignore
              value={workingHours[day]?.end || ""}
              onChange={(val: any) => {
                onWorkingHoursChange(val, day, "end");
              }}
            />
          </Row>
        </div>
      ))}
      <Button
        onClick={() => {
          let draftWorkingHours = reverseWorkingHours(workingHours);
          updateMarketPlaceWorkingHours(branchMarketplaceId, marketplaceId, draftWorkingHours).then(() =>
            successfulNotification()
          );
        }}
        buttonType="blue">
        Sačuvaj izmene radnog vremena
      </Button>
    </>
  );
};

const Row = ({ children }: any) => {
  return <div style={{ display: "flex", alignItems: "center", gap: 5 }}>{children}</div>;
};

const updateMarketPlaceDetails = (marketplaceData: any) => {
  let marketplaceDataDraft =  marketplaceData || {};

  marketplaceDataDraft.pictures = marketplaceData?.pictures?.map((pic: any) => ({
    imageLink: pic,
    priority: 1,
    isSmall: 0
  }))

  const formData = new FormData();
  formData.append("marketplaceRequestString", JSON.stringify(marketplaceData));
  return axios.put(baseUrl + `/marketplace`, formData);
};

const updateMarketPlaceWorkingHours = (branchId: any, marketplaceId: any, workingHours: any) => {
  return axios.put(baseUrl + `/marketplace/update/opening_hours/${marketplaceId}/${branchId}`, workingHours);
};

const DAYS = {
  monday: { label: "Ponedeljak" },
  tuesday: { label: "Utorak" },
  wednesday: { label: "Sreda" },
  thursday: { label: "Cetvrtak" },
  friday: { label: "Petak" },
  saturday: { label: "Subota" },
  sunday: { label: "Nedelja" },
};

const reverseWorkingHours = (structuredHours: any) => {
  const rawHours = {};

  for (const [day, time] of Object.entries(structuredHours)) {
    if (typeof time === "string") {
      // @ts-ignore
      rawHours[day] = time;
      // @ts-ignore
    } else if (time.start == time.end) {
      // @ts-ignore
      rawHours[day] = "Ne radi";
    } else {
      // @ts-ignore

      rawHours[day] = `${time.start} do ${time.end}`;
    }
  }

  return rawHours;
};
